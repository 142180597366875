const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: theme.zindex.header,
      background: 'transparent',
      transition: `all 0.5s ${theme.easings.garret}`,
      pointerEvents: 'none',
      '& a': {
        pointerEvents: 'all',
      },
    },
    minimize: {
      background: theme.colors[1],
    },
    wrapper: {
      paddingTop: 20,
      paddingBottom: 20,
      extend: theme.wrapperFrame,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    mainMenu: {
      display: 'flex',
      alignItems: 'center',
      '& li': {
        fontSize: 12,
        fontFamily: theme.fonts[1],
        textTransform: 'uppercase',
        letterSpacing: '0.15em',
        marginLeft: 50,
      },
    },
    link: {
      color: theme.colors[3],
      extend: theme.link,
      '&:before': {
        height: 2,
        bottom: -1,
      },
    },
    btn: {
      padding: [12, 22],
      borderRadius: 30,
      color: theme.colors[1],
      background: 'linear-gradient(120deg, #F08E4A, #EE472C)',
      boxShadow: `3px 7px 15px ${theme.getRgba(theme.colors[3], 0.4)}`,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    wrapper: {
      paddingTop: 15,
      paddingBottom: 15,
    },
    mainMenu: {
      '& li': {
        fontSize: 11,
        marginLeft: 20,
      },
    },
    link: {
      display: 'none',
    },
    btn: {
      boxShadow: `3px 7px 10px ${theme.getRgba(theme.colors[3], 0.4)}`,
      display: 'inline-block',
      transform: 'translateY(-4px)',
    },
  }

  return output
}

export default style
