import { memo, forwardRef } from 'react'
import classNames from 'classnames'
import { createUseStyles, useTheme } from 'react-jss'
import DelayLink from '@/components/DelayLink'
import { CURRENT_LANG } from '@/constants'
import style from './style'

const useStyles = createUseStyles(style)

const Logo = forwardRef(({
  tabIndex,
  className,
}, ref) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div
      className={classNames({
        [classes.root]: true,
        Logo__root: true,
        [className]: className,
      })}
      ref={ref}
    >
      <DelayLink
        to={CURRENT_LANG}
        className={classes.logo}
        tabIndex={tabIndex}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 374.7 95.1"
          width={theme.header.logo.width}
          height={theme.header.logo.height}
        >
          <g id="osmosi">
            <path d="M71.9,34.6c0,9.8-3.5,18.2-10.5,25.3C54.3,67,45.9,70.5,36,70.5c-9.7,0-18.2-3.4-25.3-10.1 C3.6,53.6,0,45.4,0,35.8C0,26,3.5,17.6,10.4,10.5C17.3,3.5,25.7,0,35.5,0c9.8,0,18.4,3.4,25.5,10.1C68.3,16.8,71.9,25,71.9,34.6z  M65.2,35c0-7.9-2.9-14.5-8.7-20.1c-5.8-5.5-12.7-8.3-20.7-8.3c-7.9,0-14.6,2.8-20.3,8.4C9.8,20.7,7,27.4,7,35.3 c0,7.9,2.8,14.7,8.5,20.3c5.7,5.6,12.5,8.4,20.4,8.4c8.1,0,15-2.8,20.7-8.5C62.4,49.9,65.2,43,65.2,35z" />
            <path d="M118.6,50.5c0,5.6-2,10.3-5.9,14.2c-4,3.9-8.8,5.9-14.4,5.9c-5.7,0-10.6-1.9-14.6-5.8c-4-3.9-6-8.7-6-14.6 h7.2c0,3.7,1.3,7,3.9,9.7s5.7,4.1,9.4,4.1c3.7,0,7-1.3,9.7-3.9c2.7-2.6,4.1-5.9,4.1-9.7c0-3.3-1.2-6.2-3.7-8.6 c-2.1-2.1-5.1-3.7-8.9-4.8L92,34.8c-3.8-1.1-6.8-3.1-9-6.1c-2.1-3-3.2-6.5-3.2-10.6c0-5.1,1.9-9.3,5.6-12.8 C89.2,1.7,93.6,0,98.8,0c5.2,0,9.5,1.8,13,5.5c3.5,3.6,5.3,8,5.3,13.2h-7.3c0-3.3-1.1-6.1-3.2-8.4s-4.8-3.5-7.9-3.5 c-3.3,0-6.2,1.1-8.6,3.4c-2.5,2.3-3.7,5-3.7,8.2c0,2.6,0.6,4.8,1.9,6.5c1.2,1.7,3.3,3,6,3.9l8.3,2.6c5.2,1.6,9.2,4,11.9,7.2 C117.2,41.7,118.6,45.7,118.6,50.5z" />
            <path d="M199.2,69.4h-6.9V9.1l-26,60.3h-5.7L134.9,9.1l0.1,60.2h-7V1.1l10.7,0l24.7,58.8l25.4-58.8l10.4,0V69.4z" />
            <path d="M281.5,34.6c0,9.8-3.5,18.2-10.5,25.3s-15.5,10.6-25.3,10.6c-9.7,0-18.2-3.4-25.3-10.1 c-7.1-6.8-10.7-15-10.7-24.6c0-9.8,3.5-18.3,10.4-25.3C227,3.5,235.4,0,245.2,0s18.4,3.4,25.5,10.1 C277.9,16.8,281.5,25,281.5,34.6z M274.9,35c0-7.9-2.9-14.5-8.7-20.1c-5.8-5.5-12.7-8.3-20.7-8.3c-7.9,0-14.6,2.8-20.3,8.4 c-5.7,5.6-8.5,12.4-8.5,20.2c0,7.9,2.8,14.7,8.5,20.3s12.5,8.4,20.4,8.4c8.1,0,15-2.8,20.7-8.5C272,49.9,274.9,43,274.9,35z" />
            <path d="M328.2,50.5c0,5.6-2,10.3-5.9,14.2c-4,3.9-8.8,5.9-14.4,5.9c-5.7,0-10.6-1.9-14.6-5.8c-4-3.9-6-8.7-6-14.6 h7.2c0,3.7,1.3,7,3.9,9.7c2.6,2.7,5.7,4.1,9.4,4.1c3.7,0,7-1.3,9.7-3.9c2.7-2.6,4.1-5.9,4.1-9.7c0-3.3-1.2-6.2-3.7-8.6 c-2.1-2.1-5.1-3.7-8.9-4.8l-7.3-2.2c-3.8-1.1-6.8-3.1-9-6.1c-2.1-3-3.2-6.5-3.2-10.6c0-5.1,1.9-9.3,5.6-12.8 c3.8-3.5,8.2-5.2,13.4-5.2s9.5,1.8,13,5.5c3.5,3.6,5.3,8,5.3,13.2h-7.3c0-3.3-1.1-6.1-3.2-8.4s-4.8-3.5-7.9-3.5 c-3.3,0-6.2,1.1-8.6,3.4c-2.5,2.3-3.7,5-3.7,8.2c0,2.6,0.6,4.8,1.9,6.5c1.2,1.7,3.3,3,6,3.9l8.3,2.6c5.2,1.6,9.2,4,11.9,7.2 C326.9,41.7,328.2,45.7,328.2,50.5z" />
            <path d="M344.7,69.4h-6.9V1.1h6.9V69.4z" />
          </g>
          {/* <g id="spa">
            <path d="M354.2,65.4c0,1-0.3,1.8-1,2.5s-1.5,1-2.5,1c-1,0-1.9-0.3-2.6-1c-0.7-0.7-1.1-1.5-1.1-2.6h1.3 c0,0.7,0.2,1.2,0.7,1.7c0.5,0.5,1,0.7,1.7,0.7c0.7,0,1.2-0.2,1.7-0.7s0.7-1,0.7-1.7c0-0.6-0.2-1.1-0.7-1.5 c-0.4-0.4-0.9-0.6-1.6-0.8l-1.3-0.4c-0.7-0.2-1.2-0.6-1.6-1.1c-0.4-0.5-0.6-1.2-0.6-1.9c0-0.9,0.3-1.6,1-2.3s1.5-0.9,2.4-0.9 c0.9,0,1.7,0.3,2.3,1c0.6,0.6,0.9,1.4,0.9,2.3h-1.3c0-0.6-0.2-1.1-0.6-1.5c-0.4-0.4-0.8-0.6-1.4-0.6c-0.6,0-1.1,0.2-1.5,0.6 s-0.7,0.9-0.7,1.5c0,0.5,0.1,0.8,0.3,1.2c0.2,0.3,0.6,0.5,1.1,0.7l1.5,0.5c0.9,0.3,1.6,0.7,2.1,1.3 C354,63.8,354.2,64.5,354.2,65.4z" />
            <path d="M363.8,60.4c0,1-0.4,1.8-1.1,2.5c-0.7,0.7-1.6,1-2.6,1h-3v4.8h-1.2V56.7h3.9c1.2,0,2.2,0.4,3,1.1 S363.8,59.3,363.8,60.4z M362.5,60.2c0-0.7-0.3-1.3-0.8-1.7c-0.6-0.4-1.2-0.7-2-0.7H357v5h2.8c0.8,0,1.4-0.2,1.9-0.7 C362.3,61.6,362.5,61,362.5,60.2z" />
            <path d="M374.7,68.7l-1.3,0l-1.7-4.1h-5.6l-1.7,4.1l-1.5,0l5.4-12.1h1.4L374.7,68.7z M371.2,63.6l-2.3-5.5l-2.3,5.5 H371.2z" />
          </g> */}
          <g id="drops">
            <path d="M244.6,10c-7.3,0-7.1,14.3-7.1,14.3c0,6.6,2.5,14.1,4,20.6c1.6,7,2.6,12.9,3,15.3l0,1.2c0,0,0-0.2,0.1-0.6  c0.1,0.4,0.1,0.6,0.1,0.6l0-1.2c0.4-2.4,1.5-8.3,3-15.3c1.5-6.5,4-14,4-20.6C251.7,24.3,252,10,244.6,10" fill="#e9482e" />
            <path d="M247.9,61.5c0,0,0-10.2,5.2-20c6-11.4,17.7-22.1,25.5-22.1c0,0,6-0.2,6,4.7c0,5.8-6.2,12.5-14.6,16.3 C260.4,44.9,252.3,50.3,247.9,61.5z" fill="#f1873c" enableBackground="new" />
            <path d="M241.3,61.5c0,0,0-10.2-5.2-20c-6-11.4-17.7-22.1-25.5-22.1c0,0-6-0.2-6,4.7c0,5.8,6.2,12.5,14.6,16.3 C228.8,44.9,236.9,50.3,241.3,61.5z" fill="#5e130f" enableBackground="new" />
          </g>
          <g id="payoff">
            <path d="M16.4,90.3h-1.5v-2.2c-0.7,0.9-1.4,1.6-2.1,1.9c-0.7,0.4-1.6,0.5-2.7,0.5c-1.8,0-3.3-0.6-4.6-1.9 c-1.3-1.2-1.9-2.8-1.9-4.7c0-1.8,0.6-3.4,1.9-4.6c1.3-1.2,2.9-1.8,4.8-1.8c0.9,0,1.8,0.2,2.5,0.6c0.7,0.4,1.4,1,2,1.9V78h1.6 V90.3z M14.8,84c0-1.3-0.4-2.4-1.3-3.3c-0.9-0.9-2-1.4-3.3-1.4c-1.4,0-2.5,0.5-3.5,1.5c-1,1-1.5,2.1-1.5,3.5 c0,1.3,0.5,2.4,1.4,3.4S8.7,89,10,89c1.4,0,2.6-0.5,3.5-1.5C14.4,86.6,14.8,85.4,14.8,84z" />
            <path d="M37.5,89.7c0,1.6-0.6,2.8-1.9,3.9c-1.2,1-2.7,1.5-4.4,1.5c-1.5,0-2.7-0.4-3.8-1.3c-1-0.8-1.7-1.8-2-3.1H27 c0.2,0.8,0.8,1.6,1.6,2.2c0.9,0.6,1.8,0.9,2.8,0.9c1.2,0,2.3-0.4,3.2-1.2c0.9-0.8,1.3-1.8,1.3-3v-1.6l-0.2,0.3 c-0.4,0.8-1.1,1.3-1.9,1.8c-0.8,0.4-1.7,0.6-2.7,0.6c-1.8,0-3.3-0.6-4.5-1.9c-1.2-1.3-1.8-2.8-1.8-4.5c0-1.7,0.6-3.3,1.8-4.6 c1.2-1.3,2.6-1.9,4.3-1.9c1.1,0,2,0.2,2.8,0.6s1.5,1,2.1,1.9V78h1.6V89.7z M35.9,84.1c0-1.3-0.5-2.4-1.5-3.4 c-1-0.9-2.2-1.4-3.5-1.4c-1.2,0-2.3,0.5-3.1,1.4c-0.9,0.9-1.3,2.1-1.3,3.3c0,1.3,0.4,2.5,1.3,3.4c0.9,1,2,1.5,3.3,1.5 c1.3,0,2.5-0.5,3.4-1.5C35.4,86.6,35.9,85.4,35.9,84.1z" />
            <path d="M58.6,84.7H47.5c0.1,1.2,0.6,2.3,1.6,3.1c0.9,0.8,2,1.2,3.3,1.2c1,0,1.8-0.2,2.5-0.7c0.7-0.5,1.3-1.1,1.7-2 h1.7c-0.5,1.3-1.2,2.4-2.3,3.2c-1.1,0.8-2.3,1.2-3.6,1.2c-1.7,0-3.2-0.6-4.5-1.9c-1.3-1.3-2-2.8-2-4.5c0-1.8,0.7-3.4,2-4.7 c1.3-1.3,2.9-1.9,4.8-1.9c1.6,0,3.1,0.6,4.3,1.9c1.2,1.3,1.8,2.7,1.8,4.2V84.7z M57,83.2c-0.2-1.2-0.8-2.1-1.6-2.8 c-0.9-0.7-1.9-1.1-3.1-1.1c-1.2,0-2.3,0.4-3.1,1.1c-0.9,0.7-1.4,1.7-1.7,2.8H57z" />
            <path d="M78.3,90.3h-1.7v-7.2c0-1.1-0.4-2-1.1-2.7c-0.8-0.7-1.7-1.1-2.7-1.1c-1,0-1.9,0.3-2.6,0.9 c-0.7,0.6-1.1,1.5-1.1,2.5v7.6h-1.7V78h1.5v1.7c1-1.3,2.3-2,3.8-2c1.6,0,2.9,0.5,4,1.6c1.1,1.1,1.6,2.4,1.6,4V90.3z" />
            <path d="M95,90.3h-9.1v-1.5l6.6-9.3h-6.4V78h8.4l0,1.6L88,88.8h7V90.3z" />
            <path d="M105,76.5h-1.6v-2.9h1.6V76.5z M105,90.3h-1.6V78h1.6V90.3z" />
            <path d="M126.5,90.3H125v-2.2c-0.7,0.9-1.4,1.6-2.1,1.9c-0.7,0.4-1.6,0.5-2.7,0.5c-1.8,0-3.3-0.6-4.6-1.9 c-1.3-1.2-1.9-2.8-1.9-4.7c0-1.8,0.6-3.4,1.9-4.6c1.3-1.2,2.9-1.8,4.8-1.8c0.9,0,1.8,0.2,2.5,0.6c0.7,0.4,1.4,1,2,1.9V78h1.6 V90.3z M124.9,84c0-1.3-0.4-2.4-1.3-3.3c-0.9-0.9-2-1.4-3.3-1.4c-1.4,0-2.5,0.5-3.5,1.5c-1,1-1.5,2.1-1.5,3.5 c0,1.3,0.5,2.4,1.4,3.4s2.1,1.4,3.4,1.4c1.4,0,2.6-0.5,3.5-1.5C124.5,86.6,124.9,85.4,124.9,84z" />
            <path d="M160.9,84.2c0,1.7-0.6,3.2-1.9,4.5c-1.3,1.2-2.8,1.9-4.6,1.9c-0.9,0-1.8-0.2-2.6-0.5s-1.4-0.8-2-1.5 l-0.4-0.5v6.6h-1.7V78h1.5v2.1l0.3-0.3c0.5-0.6,1.2-1.2,2-1.5c0.9-0.4,1.8-0.6,2.8-0.6c1.7,0,3.2,0.6,4.5,1.9 S160.9,82.5,160.9,84.2z M159.2,84.1c0-1.3-0.5-2.4-1.4-3.4c-0.9-1-2-1.4-3.3-1.4c-1.5,0-2.7,0.5-3.7,1.4c-1,0.9-1.5,2.1-1.5,3.5 c0,1.4,0.5,2.5,1.5,3.4s2.2,1.4,3.6,1.4c1.4,0,2.5-0.5,3.4-1.4C158.7,86.6,159.2,85.5,159.2,84.1z" />
            <path d="M181.8,84.7h-11.1c0.1,1.2,0.6,2.3,1.6,3.1c0.9,0.8,2,1.2,3.3,1.2c1,0,1.8-0.2,2.5-0.7s1.3-1.1,1.7-2h1.7 c-0.5,1.3-1.2,2.4-2.3,3.2c-1.1,0.8-2.3,1.2-3.6,1.2c-1.7,0-3.2-0.6-4.5-1.9c-1.3-1.3-2-2.8-2-4.5c0-1.8,0.7-3.4,2-4.7 c1.3-1.3,2.9-1.9,4.8-1.9c1.6,0,3.1,0.6,4.3,1.9c1.2,1.3,1.8,2.7,1.8,4.2V84.7z M180.1,83.2c-0.2-1.2-0.8-2.1-1.6-2.8 c-0.9-0.7-1.9-1.1-3.1-1.1c-1.2,0-2.3,0.4-3.1,1.1c-0.9,0.7-1.4,1.7-1.7,2.8H180.1z" />
            <path d="M196.2,79.4c-0.3,0-0.6,0-1,0.1s-0.6,0.3-0.9,0.5c-0.5,0.4-0.8,0.7-1,1c-0.3,0.5-0.4,1.1-0.4,1.9v7.4h-1.7 V78h1.5v1.7c0.2-0.3,0.4-0.6,0.7-0.9c0.3-0.3,0.5-0.5,0.9-0.6s0.6-0.3,0.9-0.4c0.3-0.1,0.6-0.1,1-0.1V79.4z" />
            <path d="M217.5,76.5h-1.6v-2.9h1.6V76.5z M217.5,90.3h-1.6V78h1.6V90.3z" />
            <path d="M228.9,90.3h-1.7V73.7h1.7V90.3z" />
            <path d="M252.2,90.3h-1.7V73.7h1.7V90.3z" />
            <path d="M273.3,90.3h-1.5v-2.2c-0.7,0.9-1.4,1.6-2.1,1.9c-0.7,0.4-1.6,0.5-2.7,0.5c-1.8,0-3.3-0.6-4.6-1.9 c-1.3-1.2-1.9-2.8-1.9-4.7c0-1.8,0.6-3.4,1.9-4.6c1.3-1.2,2.9-1.8,4.8-1.8c0.9,0,1.8,0.2,2.5,0.6s1.4,1,2,1.9V78h1.6V90.3z  M271.8,84c0-1.3-0.4-2.4-1.3-3.3c-0.9-0.9-2-1.4-3.3-1.4c-1.4,0-2.5,0.5-3.5,1.5c-1,1-1.5,2.1-1.5,3.5c0,1.3,0.5,2.4,1.4,3.4 s2.1,1.4,3.4,1.4c1.4,0,2.6-0.5,3.5-1.5S271.8,85.4,271.8,84z" />
            <path d="M293.3,78l-5.2,12.4h-1.7L281.4,78l1.7,0l4.2,10.2l4.2-10.2L293.3,78z" />
            <path d="M313,84.1c0,1.8-0.6,3.3-1.9,4.6c-1.3,1.3-2.8,1.9-4.6,1.9c-1.7,0-3.2-0.6-4.4-1.9c-1.2-1.3-1.8-2.8-1.8-4.5 c0-1.8,0.6-3.3,1.9-4.6s2.8-1.9,4.6-1.9c1.7,0,3.2,0.6,4.4,1.9C312.4,80.9,313,82.4,313,84.1z M311.3,84.1c0-1.3-0.5-2.4-1.4-3.4 c-0.9-0.9-2-1.4-3.3-1.4c-1.3,0-2.4,0.5-3.4,1.4c-0.9,0.9-1.4,2.1-1.4,3.4c0,1.3,0.4,2.5,1.3,3.5c0.9,1,1.9,1.5,3.2,1.5 c1.4,0,2.5-0.5,3.5-1.5C310.8,86.6,311.3,85.4,311.3,84.1z" />
            <path d="M326.5,79.4c-0.3,0-0.6,0-1,0.1s-0.6,0.3-0.9,0.5c-0.5,0.4-0.8,0.7-1,1c-0.3,0.5-0.4,1.1-0.4,1.9v7.4h-1.7 V78h1.5v1.7c0.2-0.3,0.4-0.6,0.7-0.9c0.3-0.3,0.5-0.5,0.9-0.6s0.6-0.3,0.9-0.4c0.3-0.1,0.6-0.1,1-0.1V79.4z" />
            <path d="M346.9,84.1c0,1.8-0.6,3.3-1.9,4.6c-1.3,1.3-2.8,1.9-4.6,1.9c-1.7,0-3.2-0.6-4.4-1.9 c-1.2-1.3-1.8-2.8-1.8-4.5c0-1.8,0.6-3.3,1.9-4.6s2.8-1.9,4.6-1.9c1.7,0,3.2,0.6,4.4,1.9C346.3,80.9,346.9,82.4,346.9,84.1z  M345.2,84.1c0-1.3-0.5-2.4-1.4-3.4c-0.9-0.9-2-1.4-3.3-1.4c-1.3,0-2.4,0.5-3.4,1.4c-0.9,0.9-1.4,2.1-1.4,3.4 c0,1.3,0.4,2.5,1.3,3.5c0.9,1,1.9,1.5,3.2,1.5c1.4,0,2.5-0.5,3.5-1.5C344.8,86.6,345.2,85.4,345.2,84.1z" />
          </g>
        </svg>
      </DelayLink>
    </div>
  )
})

export default memo(Logo)
