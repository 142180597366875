import { useEffect, useCallback } from 'react'
import { useTheme } from 'react-jss'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import usePrevious from '@/hooks/usePrevious'
import * as loadingActions from '@/actions/loading'

const useChangePathname = () => {
  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const setIsLoading = useCallback((bool) => dispatch(loadingActions.setLoadingValue('isLoading', bool)), [dispatch])
  const setReady = useCallback((bool) => dispatch(loadingActions.setLoadingValue('isReady', bool)), [dispatch])
  const setFirstLoad = useCallback((bool) => dispatch(loadingActions.setLoadingValue('isFirstLoad', bool)), [dispatch])
  const setPageAnimationReady = useCallback((bool) => dispatch(loadingActions.setLoadingValue('isPageAnimationReady', bool)), [dispatch])

  const theme = useTheme()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { pathname, isReady, layoutStyle } = useSelector((state) => ({
    pathname: state.router.location.pathname,
    isReady: state.loading.isReady,
    layoutStyle: state.layout.style,
  }), shallowEqual)

  /*------------------------------
  Manage Loading on change pathname
  ------------------------------*/
  const prevPathname = usePrevious(pathname)
  useEffect(() => {
    if (
      prevPathname !== undefined
      && prevPathname !== pathname
    ) {
      setPageAnimationReady(false)
      setReady(false)
      setFirstLoad(false)
      setIsLoading(true)
    }
  }, [pathname])

  useEffect(() => {
    document.documentElement.style.setProperty('--scrollbar-thumb', theme.getRgba(theme.colors[2], 0.4))
  }, [layoutStyle])

  /*------------------------------
  Remove Spinner
  ------------------------------*/
  useEffect(() => {
    if (isReady) setIsLoading(false)
  }, [isReady])

  return null
}

export default useChangePathname
