import {
  RECEIVE_PRODUCT_LIST,
} from '@/actions/types'

const initialState = {
  products: {},
  filteredProducts: [],
  filters: {},
  totalPosts: 0,
  totalPages: 1,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PRODUCT_LIST:
      state = {
        ...state,
        products: {
          ...state.cpt,
          [action.key]: action.payload,
        },
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}
