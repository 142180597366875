import { SET_MODAL_VALUE } from '@/actions/types'

const setModalValue = (key, value) => ({
  type: SET_MODAL_VALUE,
  payload: value,
  key,
})

export {
  setModalValue,
}
