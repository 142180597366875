import { RECEIVE_NAV } from '@/actions/types'
import { camelCase } from '@/utils/strings'

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_NAV:
      state = {
        ...state,
        [camelCase(action.key)]: action.payload,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}
