import { SET_CURSOR_VALUE } from '@/actions/types'

const initialState = {
  isVisible: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURSOR_VALUE:
      state = {
        ...state,
        [action.key]: action.payload,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}
