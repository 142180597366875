import { memo, useCallback, useEffect, useRef } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import * as loadingActions from '@/actions/loading'
import style from './style'

const Loader = ({ classes }) => {
  const $root = useRef()
  const $logo = useRef()
  const $drop1 = useRef()
  const $drop2 = useRef()
  const $drop3 = useRef()
  const $spinner = useRef()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isSiteLoaded, isLoaderExited } = useSelector((state) => ({
    isSiteLoaded: state.loading.isSiteLoaded,
    isLoaderExited: state.loading.isLoaderExited,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const setLoaderExited = useCallback((bool) => dispatch(loadingActions.setLoadingValue('isLoaderExited', bool)), [dispatch])

  /*------------------------------
  On Complete Animation
  ------------------------------*/
  const onCompleteLoaderEnd = useCallback(() => setLoaderExited(true), [])

  /*------------------------------
  Loader End Animation
  ------------------------------*/
  const animateLoaderEnd = useCallback(() => {
    const timeline = gsap.timeline({ onComplete: onCompleteLoaderEnd })
    timeline
      .to([$logo.current, $spinner.current], ({
        autoAlpha: 0,
      }), 1.2)
      .to($root.current, ({
        autoAlpha: 0,
      }), 2)
  }, [])

  /*------------------------------
  Animate Loader End
  ------------------------------*/
  useEffect(() => {
    if (isSiteLoaded) animateLoaderEnd()
  }, [isSiteLoaded])

  /*------------------------------
  Render
  ------------------------------*/
  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.hide]: isLoaderExited,
      })}
      ref={$root}
    >
      <div className={classes.logoContainer}>
        <svg
          ref={$logo}
          className={classes.logo}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 20.24"
          width="50"
          height="50"
        >
          {/*  */}
        </svg>
      </div>
      <div className={classes.spinnerContainer}>
        <svg
          ref={$spinner}
          className={classes.spinner}
          viewBox="0 0 50 50"
          width="50"
          height="50"
        >
          <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          />
        </svg>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 80.1 51.4"
          className={classes.drops}
        >
          <path
            ref={$drop1}
            fill="#E9482E"
            d="M40.1,0C32.8,0,33,14.3,33,14.3c0,6.6,2.5,14.1,4,20.6c1.6,7,2.6,12.9,3,15.3l0,1.2c0,0,0-0.2,0.1-0.6 c0.1,0.4,0.1,0.6,0.1,0.6l0-1.2c0.4-2.4,1.5-8.3,3-15.3c1.5-6.5,4-14,4-20.6C47.2,14.3,47.4,0,40.1,0"
          />
          <path
            ref={$drop2}
            fill="#F1873C"
            d="M43.4,51.4c0,0,0-10.2,5.2-20c6-11.4,17.7-22.1,25.5-22.1c0,0,6-0.2,6,4.7c0,5.8-6.2,12.5-14.6,16.3 C55.9,34.9,47.8,40.3,43.4,51.4z"
          />
          <path
            ref={$drop3}
            fill="#5E130F"
            d="M36.7,51.4c0,0,0-10.2-5.2-20C25.5,20.1,13.9,9.4,6,9.4c0,0-6-0.2-6,4.7c0,5.8,6.2,12.5,14.6,16.3 C24.3,34.9,32.4,40.3,36.7,51.4z"
          />
        </svg>

      </div>
    </div>
  )
}

export default injectSheet(style)(memo(Loader))
