import { memo } from 'react'
import { createUseStyles } from 'react-jss'
import Loader from '@/components/Loader'
import Header from '@/components/Header'
import Spinner from '@/components/Spinner'
import Modal from '@/components/Modal'
import SVGSprite from '@/components/SVGSprite'
import useFontPreload from '@/hooks/useFontPreload'
import useScrollTriggerProxy from '@/hooks/useScrollTriggerProxy'
import useChangePathname from '@/hooks/useChangePathname'
import global from '@/styles/global'

const useStyles = createUseStyles(global)

const Layout = ({ children }) => {
  const classes = useStyles()

  /*------------------------------
  Precache fonts
  ------------------------------*/
  useFontPreload({ fonts: ['Poppins', 'PoppinsBold'] })

  /*------------------------------
  Init Scroll Trigger Proxy
  ------------------------------*/
  useScrollTriggerProxy()

  /*------------------------------
  Manage Change Pathname
  ------------------------------*/
  useChangePathname()

  return (
    <div className={`${classes.root} content-root`}>
      <div className="wrapper">
        {children}
      </div>
      <SVGSprite />
      <Spinner />
      <Modal />
      <Header />
      {/* <Overlay /> */}
      {/* <ModalDownload /> */}
      {/* <CursorFollow /> */}
      <Loader />
    </div>
  )
}

export default memo(Layout)
