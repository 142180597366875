import axios from 'axios'
import { setupCache } from 'axios-cache-adapter'
import { API_BASE_URL, API_CACHE_BASE_URL, AUTH } from '@/constants'

const cache = setupCache({ maxAge: 15 * 60 * 1000 }) // 15 minutes

const mainAxios = axios.create({
  baseURL: API_BASE_URL,
  adapter: cache.adapter,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Basic ${btoa(`${AUTH.username}:${AUTH.password}`)}`,
  },
})

const mainCacheAxios = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? API_CACHE_BASE_URL : API_BASE_URL,
  adapter: cache.adapter,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Basic ${btoa(`${AUTH.username}:${AUTH.password}`)}`,
  },
})

export {
  mainAxios,
  mainCacheAxios,
}
