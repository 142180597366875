const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: theme.zindex.loader,
      background: theme.colors[1],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    hide: {
      // pointerEvents: 'none',
    },
    logoContainer: {
      width: 80,
      height: 80,
    },
    logo: {
      width: '100%',
      height: '100%',
      fill: theme.colors[3],
      transition: `all 0.75s ${theme.easings.garret}`,
    },
    placeholder: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: -1,
      '& svg': {
        width: 440,
        height: 300,
        margin: '28px 0 0 -134px',
        maxWidth: 'none',
        '& path': {
          fill: 'red!important',
        },
      },
    },
    logoFilled: {
      fill: theme.colors[3],
    },
    spinnerContainer: {
      width: 94,
      height: 94,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      transition: `all 0.75s ${theme.easings.garret}`,
    },
    spinner: {
      width: '100%',
      height: '100%',
      transformOrigin: 'center',
      animation: '$rotate 2s linear infinite',
      transition: 'all 0.3s ease-in',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // pointerEvents: 'none',
      '& .path': {
        stroke: theme.colors[3],
        strokeWidth: 3.8,
        fill: 'none',
        animation: '$dash 2.5s ease-in-out infinite',
      },
    },
    drops: {
      position: 'absolute',
      zIndex: 1,
      top: '50%',
      left: '50%',
      transform: 'translate(-52%, -49%) scale(1.9)',
    },
    '@keyframes rotate': {
      from: { transform: 'rotate(0)' },
      to: { transform: 'rotate(360deg)' },
    },
    '@keyframes fadeIn': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    '@keyframes dash': {
      '0%': {
        strokeDasharray: '1, 150',
        strokeDashoffset: 0,
      },
      '50%': {
        strokeDasharray: '90, 150',
        strokeDashoffset: -35,
      },
      '100%': {
        strokeDasharray: '90, 150',
        strokeDashoffset: -124,
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    drops: {
      transform: 'translate(-50%, -50%) scale(1)',
    },
  }
  return output
}

export default style
