import { memo, useRef, useEffect, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { createUseStyles } from 'react-jss'
import HTMLParse from 'react-html-parser'
import gsap from 'gsap'
import * as modalActions from '@/actions/modal'
import style from './style'

const useStyles = createUseStyles(style)

const Modal = () => {
  const $root = useRef()
  const classes = useStyles()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { modalOpen, modalContent } = useSelector((state) => ({
    strings: state.options.strings,
    modalOpen: state.modal.open || false,
    modalContent: state.modal.content || '',
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const setModalOpen = useCallback((bool) => dispatch(modalActions.setModalValue('open', bool)), [dispatch])

  /*------------------------------
  Handle Close
  ------------------------------*/
  const handleClose = useCallback(() => {
    setModalOpen(false)
  }, [])

  useEffect(() => {
    gsap.to($root.current, {
      autoAlpha: modalOpen ? 1 : 0,
      pointerEvents: modalOpen ? 'all' : 'none',
    })
  }, [modalOpen])

  return (
    <div
      className={classes.root}
      ref={$root}
      role="dialog"
      aria-modal="true"
    >
      <button className={classes.overlay} onClick={handleClose} aria-label="close-modal" />
      <div className={classes.content}>
        <div className={classes.wrapper}>
          <button
            className={classes.close}
            onClick={handleClose}
            aria-label="close-modal"
          >
            <svg viewBox="0 0 12.021 12.021">
              <g id="Raggruppa_23" transform="translate(-548.008 -704.985) rotate(45)">
                <rect id="Rettangolo_41" width="15" height="2" rx="1" transform="translate(887 110)" fill="#fff" />
                <rect id="Rettangolo_42" width="15" height="2" rx="1" transform="translate(895.5 103.5) rotate(90)" fill="#fff" />
              </g>
            </svg>
          </button>
          <div className={classes.scrollable}>
            <h2 className={classes.title}>{HTMLParse(modalContent?.title?.rendered)}</h2>
            <div className={classes.text}>
              {HTMLParse(modalContent?.content?.rendered)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Modal)
