/* eslint-disable jsx-control-statements/jsx-use-if-tag */
import { memo, useRef, useCallback, useState } from 'react'
import classNames from 'classnames'
import { useContext } from 'use-context-selector'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import Logo from '@/components/Logo'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import { useRaf, useResize } from '@/components/Handlers'
import * as modalActions from '@/actions/modal'
import Modernizr from '@/vendors/modernizr'
import { isInternal } from '@/utils/path'
import style from './style'

const useStyles = createUseStyles(style)

const Header = ({ treshold }) => {
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { mainMenu, page, strings } = useSelector((state) => ({
    page: state.content.pages || {},
    strings: state.options.strings,
    languages: state.locale.languages,
    mainMenu: state.nav.mainMenu || [],
  }), shallowEqual)

  const classes = useStyles()
  const $root = useRef()
  const rootBounds = useRef()
  const { scrollbar, scroll } = useContext(ScrollbarContext)
  const [isTop, setTop] = useState(true)

  const scrollY = useRef({
    y: 0,
  })

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const setModalOpen = useCallback((bool) => dispatch(modalActions.setModalValue('open', bool)), [dispatch])
  const setModalContent = useCallback((data) => dispatch(modalActions.setModalValue('content', data)), [dispatch])

  /*------------------------------
  Calculate Header Bounds
  ------------------------------*/
  const handleResize = useCallback(() => {
    if ($root.current) {
      rootBounds.current = $root.current.getBoundingClientRect()
    }
  }, [])

  /*------------------------------
  Handle Resize
  ------------------------------*/
  useResize(() => {
    handleResize()
  })

  /*------------------------------
  Handle Modal
  ------------------------------*/
  const handleModal = (e) => {
    e.preventDefault()
    const slug = e.target.href.split('/').reverse()[1]
    setModalOpen(true)
    setModalContent(page[slug])
  }

  const update = () => {
    if (scroll.current.y <= treshold) setTop(true)
    if (scroll.current.y > treshold) setTop(false)
  }

  useRaf(() => {
    update()
  }, [isTop])

  /*------------------------------
  Render Main Bar
  ------------------------------*/
  const renderMainBar = () => {
    return (
      <div className={classes.wrapper}>
        <Logo
          className={classNames({
            [classes.logo]: true,
          })}
        />
        <ul className={classes.mainMenu}>
          {mainMenu.map((item, index) => (
            isInternal(item.url) ? (
              <li key={index.toString()}>
                <a onClick={handleModal} href={item.url} className={classes.link}>{item.title}</a>
              </li>
            ) : (
              <li key={index.toString()}>
                <a href={item.url} className={classes.link} target="_blank">{item.title}</a>
              </li>
            )
          ))}
          <li>
            <a
              href="#contattaci"
              onClick={(e) => {
                e.preventDefault()
                if (!Modernizr.nativescroll) {
                  scrollbar.scrollTo('#contattaci')
                } else {
                  gsap.fromTo(scrollY.current, {
                    y: scroll.current.y,
                  }, {
                    y: document.getElementById('contattaci').offsetTop,
                    duration: 1,
                    ease: 'power3.inOut',
                    onUpdate: () => {
                      scrollbar.el.scrollTop = scrollY.current.y
                    },
                  })
                }
              }}
              className={classes.btn}
            >
              {strings.contattaci_label}
            </a>
          </li>
        </ul>
      </div>
    )
  }

  return (
    <>
      <header
        className={classNames({
          [classes.root]: true,
          [classes.minimize]: !isTop,
        })}
        ref={$root}
      >
        {renderMainBar()}
      </header>
    </>
  )
}

Header.defaultProps = {
  treshold: 200,
  menuLabel: 'Menu',
}

export default memo(Header)
