const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      width: '100vw',
      height: 'var(--vh)',
      top: 0,
      left: 0,
      zIndex: theme.zindex.modal,
      backdropFilter: 'blur(10px)',
    },
    content: {
      borderRadius: [30, 0, 0, 30],
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100%',
      maxWidth: 750,
      height: '100%',
      background: '#E9E9E9',
    },
    overlay: {
      position: 'absolute',
      zIndex: 0,
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    },
    scrollable: {
      position: 'absolute',
      zIndex: 1,
      width: '100%',
      top: 50,
      bottom: 50,
      right: 0,
      left: 0,
      overflowY: 'scroll',
      overflowX: 'hidden',
      padding: [0, 50],
    },
    close: {
      position: 'absolute',
      zIndex: 10,
      top: 'calc(50% - 30px)',
      left: -30,
      width: 60,
      height: 60,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'linear-gradient(120deg, #F08E4A, #EE472C)',
      boxShadow: `3px 7px 15px ${theme.getRgba(theme.colors[3], 0.4)}`,
      borderRadius: '50%',
      cursor: 'pointer',
      '& svg': {
        width: 12,
        height: 12,
        fill: theme.colors[1],
        transform: 'scale(1)',
        transition: 'transform .5s ease-out',
      },
      '&:hover': {
        '& svg': {
          transform: 'scale(1.2) rotate(180deg)',
        },
      },
    },
    title: {
      fontFamily: theme.fonts[1],
      fontSize: 48,
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
      marginBottom: 30,
      color: theme.colors[3],
    },
    text: {
      '& h2': {
        fontFamily: theme.fonts[0],
        fontSize: 36,
        letterSpacing: '0.05em',
        textTransform: 'uppercase',
        marginTop: 60,
        marginBottom: 30,
        lineHeight: 1.1,
      },
      '& li': {
        position: 'relative',
        zIndex: 1,
        paddingLeft: 30,
        marginBottom: 20,
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 10,
          left: 4,
          width: 5,
          height: 5,
          borderRadius: '50%',
          background: theme.colors[3],
        },
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    title: {
      fontSize: 30,
    },
    content: {
      borderRadius: 0,
    },
    scrollable: {
      top: 80,
      bottom: 30,
      padding: [0, 30],
    },
    close: {
      top: 13,
      left: 'auto',
      right: 30,
      width: 38,
      height: 38,
      boxShadow: `3px 7px 10px ${theme.getRgba(theme.colors[3], 0.4)}`,
    },
    text: {
      '& h2': {
        fontSize: 26,
        lineHeight: 1.2,
      },
      '& li': {
        '&:before': {
          top: 6,
        },
      },
    },
  }

  return output
}

export default style
